import React from 'react'

const Newsletter = ({ data = {} }) => {
  const { dripPlaceholderID } = data || {}
  if (!dripPlaceholderID) return null

  return <div id={dripPlaceholderID} />
}

export default Newsletter
